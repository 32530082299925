var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      return _vm.fetchContractDashboardData()
                    }
                  }
                },
                [
                  _c("h1", [
                    _c("i", {
                      staticClass: "bx bx-rotate-right",
                      staticStyle: { float: "right" }
                    })
                  ])
                ]
              ),
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Détails ")
              ]),
              _c("div", { staticClass: "table-responsive mb-0" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Référence :")
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.contractData.contractRef))])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Prestataire :")
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.contractData.contractSupplier))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Date de création :")
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.contractData.createdAt))])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Date début de validité :")
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.contractData.contractPeriodFrom))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Date fin de validité :")
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.contractData.contractPeriodTo))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Status :")
                      ]),
                      _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.contractData.contractStatus)
                          }
                        })
                      ])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Réalisation (%) :")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.contractData.contractRealisationPercent)
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Réalisation (DZD) :")
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.contractData.contractRealisationAmnt))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-5" }, [
                _vm._v("Détails Réalisations")
              ]),
              _c("div", {}, [
                _c("ul", { staticClass: "verti-timeline list-unstyled" }, [
                  _c("li", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-timeline-dot" }, [
                      _c("i", { staticClass: "bx bx-right-arrow-circle" })
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-3" }, [
                        _c("i", { staticClass: "bx bx-code h4 text-primary" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", [
                          _c("h5", { staticClass: "font-size-15" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "javascript: void(0);" }
                              },
                              [_vm._v("Restauration (3.45%)")]
                            )
                          ]),
                          _c("span", { staticClass: "text-primary" }, [
                            _vm._v("2345,0 DZD")
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-timeline-dot" }, [
                      _c("i", { staticClass: "bx bx-right-arrow-circle" })
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-3" }, [
                        _c("i", { staticClass: "bx bx-code h4 text-primary" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", [
                          _c("h5", { staticClass: "font-size-15" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "javascript: void(0);" }
                              },
                              [_vm._v("Hébergement (8.98 %)")]
                            )
                          ]),
                          _c("span", { staticClass: "text-primary" }, [
                            _vm._v("8769.2 DZD")
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-timeline-dot" }, [
                      _c("i", { staticClass: "bx bx-right-arrow-circle" })
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-3" }, [
                        _c("i", { staticClass: "bx bx-edit h4 text-primary" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", [
                          _c("h5", { staticClass: "font-size-15" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "javascript: void(0);" }
                              },
                              [_vm._v("3D (12.2%)")]
                            )
                          ]),
                          _c("span", { staticClass: "text-primary" }, [
                            _vm._v("3459.09 DZD")
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-5" }, [
                _vm._v(" Garanties "),
                _c("button", { staticClass: "btn btn-primary float-right" }, [
                  _vm._v("Nouvelle garantie")
                ])
              ]),
              _c("div", {}, [
                _c("ul", { staticClass: "verti-timeline list-unstyled" }, [
                  _c("li", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-timeline-dot" }, [
                      _c("i", { staticClass: "bx bx-right-arrow-circle" })
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-3" }, [
                        _c("i", { staticClass: "bx bx-code h4 text-primary" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", [
                          _c("h5", { staticClass: "font-size-15" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "javascript: void(0);" }
                              },
                              [_vm._v("#Ref")]
                            )
                          ]),
                          _c("span", { staticClass: "text-primary" }, [
                            _vm._v("2345,0 DZD")
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-5" }, [
                _vm._v(" Dernière Pénalité "),
                _c("button", { staticClass: "btn btn-primary float-right" }, [
                  _vm._v("Nouvelle pénalité")
                ])
              ]),
              _c("div", {}, [
                _c("ul", { staticClass: "verti-timeline list-unstyled" }, [
                  _c("li", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-timeline-dot" }, [
                      _c("i", { staticClass: "bx bx-right-arrow-circle" })
                    ]),
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-3" }, [
                        _c("i", { staticClass: "bx bx-code h4 text-primary" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", [
                          _c("h5", { staticClass: "font-size-15" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-dark",
                                attrs: { href: "javascript: void(0);" }
                              },
                              [_vm._v("#Ref")]
                            )
                          ]),
                          _c("span", { staticClass: "text-primary" }, [
                            _vm._v("2345,0 DZD")
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", [
                  _vm._v("Pour voir la liste complète, veuillez "),
                  _c("a", { attrs: { href: "" } }, [_vm._v("cliquez Ici")])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-8" }, [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.mainAmountsSections, function(amntCard) {
              return _c(
                "div",
                { key: amntCard.icon, staticClass: "col-md-4" },
                [
                  _c("Stat", {
                    attrs: {
                      icon: amntCard.icon,
                      title: amntCard.name,
                      value: amntCard.allocated_amount
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title mb-4" }, [
                  _vm._v("Réalisation mensuelles")
                ]),
                _c("div", {
                  staticClass: "apex-charts",
                  attrs: { id: "revenue-chart" }
                }),
                _c("apexchart", {
                  staticClass: "apex-charts",
                  attrs: {
                    type: "bar",
                    height: "300",
                    series: _vm.revenueChart.series,
                    options: _vm.revenueChart.chartOptions
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Opérations Hébergement")
              ]),
              _c("div", { staticClass: "table-responsive mb-0" }, [
                _c("table", { staticClass: "table table-nowrap table-hover" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Projects")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Start Date")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Deadline")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Budget")])
                    ])
                  ]),
                  _c("tbody")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Opérations Restauration")
              ]),
              _c("div", { staticClass: "table-responsive mb-0" }, [
                _c("table", { staticClass: "table table-nowrap table-hover" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Projects")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Start Date")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Deadline")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Budget")])
                    ])
                  ]),
                  _c("tbody")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Opérations 3D")
              ]),
              _c("div", { staticClass: "table-responsive mb-0" }, [
                _c("table", { staticClass: "table table-nowrap table-hover" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("ID")]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Projects")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Start Date")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Deadline")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Budget")])
                    ])
                  ]),
                  _c("tbody")
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }