<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';
import { revenueChart } from "./data-profile";
import Stat from "@/components/widgets/stat";

export default {
  page: {
    title: "Détails",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Stat },
  data() {
    return {
      revenueChart: revenueChart,
      title: "Détails",
      uuid: null,
      contractData: {
          contractRef: "",
          contractSupplier: "",
          contractAmnt: "",
          contractType: "",
          contractPeriodFrom: "",
          contractPeriodTo: "",
          createdAt: "",
          contractStatus: "",
          contractRealisationPercent: "",
          contractRealisationAmnt: "",
      },
      items: [
        {
          text: "Contacts",
          href: "/",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
      mainAmountsSections: [],
    };
  },

  mounted() {
    this.uuid = this.$route.params.uid;
    this.fetchContractDashboardData();
  },

  methods: {
    fetchContractDashboardData() {
      var _this = this;
      this.$http
        .post(
          "/contracts/contracts/getContractDashboardData/" + this.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var contract = res.data.original.data.contract;
          _this.mainAmountsSections = res.data.original.data.mainAmountsSections;
          switch (status) {
            case 200:
              _this.contractData.contractRef = contract.ref;
              _this.contractData.contractSupplier = contract.supplier;
              _this.contractData.contractAmnt = contract.totalAmnt;
              _this.contractData.contractType = contract.type;
              _this.contractData.contractPeriodFrom = contract.startDate;
              _this.contractData.contractPeriodTo = contract.endDate;
              _this.contractData.createdAt = contract.createdAt;
              _this.contractData.contractStatus = contract.status;
              _this.contractData.contractRealisationPercent = contract.realisation_percent;
              _this.contractData.contractRealisationAmnt = contract.realisation_amnt;

              

              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
        <a @click="fetchContractDashboardData()" href="javascript:void()"><h1><i class="bx bx-rotate-right" style="float: right;"></i> </h1></a>
            <h4 class="card-title mb-4">Détails </h4>
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">Référence :</th>
                    <td>{{ contractData.contractRef }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Prestataire :</th>
                    <td>{{ contractData.contractSupplier }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date de création :</th>
                    <td>{{ contractData.createdAt }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date début de validité :</th>
                    <td>{{ contractData.contractPeriodFrom }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date fin de validité :</th>
                    <td>{{ contractData.contractPeriodTo }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Status :</th>
                    <td><span v-html="contractData.contractStatus"></span></td>
                  </tr>
                  <tr>
                    <th scope="row">Réalisation (%) :</th>
                    <td>{{ contractData.contractRealisationPercent }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Réalisation (DZD) :</th>
                    <td>{{ contractData.contractRealisationAmnt }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-5">Détails Réalisations</h4>
            <div class>
              <ul class="verti-timeline list-unstyled">
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="mr-3">
                      <i class="bx bx-code h4 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark"
                            >Restauration (3.45%)</a
                          >
                        </h5>
                        <span class="text-primary">2345,0 DZD</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="mr-3">
                      <i class="bx bx-code h4 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark"
                            >Hébergement (8.98 %)</a
                          >
                        </h5>
                        <span class="text-primary">8769.2 DZD</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="mr-3">
                      <i class="bx bx-edit h4 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark"
                            >3D (12.2%)</a
                          >
                        </h5>
                        <span class="text-primary">3459.09 DZD</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-5">
              Garanties
              <button class="btn btn-primary float-right">Nouvelle garantie</button>
              </h4>
            <div class>
              <ul class="verti-timeline list-unstyled">
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="mr-3">
                      <i class="bx bx-code h4 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark"
                            >#Ref</a
                          >
                        </h5>
                        <span class="text-primary">2345,0 DZD</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-5">
              Dernière Pénalité
              <button class="btn btn-primary float-right">Nouvelle pénalité</button>
              </h4>
            <div class>
              <ul class="verti-timeline list-unstyled">
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="mr-3">
                      <i class="bx bx-code h4 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark"
                            >#Ref</a
                          >
                        </h5>
                        <span class="text-primary">2345,0 DZD</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <p>Pour voir la liste complète, veuillez <a href="">cliquez Ici</a> </p> 
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-8">
        <div class="row">
          <div v-for="amntCard of mainAmountsSections" :key="amntCard.icon" class="col-md-4">
            <Stat :icon="amntCard.icon" :title="amntCard.name" :value="amntCard.allocated_amount" />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Réalisation mensuelles</h4>
            <div id="revenue-chart" class="apex-charts"></div>
            <apexchart
              class="apex-charts"
              type="bar"
              height="300"
              :series="revenueChart.series"
              :options="revenueChart.chartOptions"
            ></apexchart>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Opérations Hébergement</h4>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Projects</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Budget</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Opérations Restauration</h4>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Projects</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Budget</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Opérations 3D</h4>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Projects</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Budget</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>