var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mini-stats-wid" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "media" }, [
        _c("div", { staticClass: "media-body" }, [
          _c("p", { staticClass: "text-muted font-weight-medium" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("h4", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.value))])
        ]),
        _c(
          "div",
          {
            staticClass:
              "mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary"
          },
          [
            _c("span", { staticClass: "avatar-title" }, [
              _c("i", { class: _vm.icon + " font-size-24" })
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }